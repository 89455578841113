import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./components/auth/PrivateRoute";
import Account from "./pages/Account";
import Campaign from "./pages/Campaign";
import CampaignCreate from "./pages/CampaignCreate";
import Home from "./pages/Home";
import Legal from "./pages/Legal";
import Login from "./pages/Login";
import NoMatch from "./pages/NoMatch";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Register from "./pages/Register";

function App() {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/" element={<PrivateRoute />}>
        <Route exact path="/" element={<Home />} />
      </Route>
      <Route exact path="/account" element={<PrivateRoute />}>
        <Route exact path="/account" element={<Account />} />
      </Route>
      <Route exact path="/campaigns/create" element={<PrivateRoute />}>
        <Route exact path="/campaigns/create" element={<CampaignCreate />} />
      </Route>
      <Route exact path="/campaigns/:campaignUuid" element={<PrivateRoute />}>
        <Route exact path="/campaigns/:campaignUuid" element={<Campaign />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;

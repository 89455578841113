import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { primaryColor } from "../utils/constants";

function LoadingPage({ h }) {
  return (
    <Center h={h || "100vh"} w="100vw">
      <Spinner color={primaryColor} size={"lg"} />
    </Center>
  );
}

export default LoadingPage;

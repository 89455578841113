import axios from "axios";
import React from "react";
import { api, url } from "../configs/api";
import { STRIPE_FEES } from "../utils/constants";
import { mapperRoute } from "../utils/helpers";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

async function createCampaign(dispatch, fields) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    const bodyFormData = new FormData();
    Object.keys(fields).forEach((field) => {
      bodyFormData.append(field, fields[field]);
    });

    const response = await axios({
      method: "POST",
      url: mapperRoute(`${api}/campaigns/create`),
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer " + token },
    });

    const campaign = response.data;
    dispatch({ type: "campaign", payload: campaign });
    dispatch({ type: "redirect_to", payload: "/campaigns/" + campaign.uuid });
    dispatch({
      type: "notify",
      payload: {
        message: "Campagne créée avec succès",
        type: "success",
      },
    });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaign",
        error,
      },
    });
  }
}
async function updateCampaign(dispatch, campaign, fields) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    const bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    Object.keys(fields).forEach((field) => {
      bodyFormData.append(field, fields[field]);
    });

    const response = await axios({
      method: "POST",
      url: mapperRoute(`${api}/campaigns/${campaign.uuid}`),
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer " + token },
    });

    // const response = await axios.put(mapperRoute(`${api}/campaigns/${campaign.uuid}`), fields, {
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // });
    dispatch({ type: "campaign", payload: response.data });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaign",
        error,
      },
    });
  }
}
async function getCampaign(dispatch, campaignUuid) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  dispatch({ type: "campaign", payload: undefined });
  try {
    // const response = await axios.get(mapperRoute(`${api}/campaigns/${campaignUuid}`));
    const response = await axios.get(mapperRoute(`${api}/campaigns/${campaignUuid}`), {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({ type: "campaign", payload: response.data });
    console.log(response.data);
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaign",
        error,
      },
    });
  }
}

async function activateCampaign(dispatch, campaign, fields) {
  dispatch({ type: "loading", payload: true });
  const token = localStorage.getItem("token");
  try {
    const payer = fields.service === "CARD" ? fields.email : fields.phone_number;
    const amountToPay = parseFloat(fields.amount) + parseFloat(fields.amount * STRIPE_FEES);
    const src = `${api}/campaigns/${campaign.uuid}/pay?currency=${fields.currency}&credit_amount=${campaign.ppc * 100}&amount=${amountToPay}&service=${fields.service}&payer=${payer}&success_url=${encodeURI(document.location.href + "?success")}&cancel_url=${encodeURI(document.location.href + "?failed")}`;
    const response = await axios.get(src, { headers: { Authorization: "Bearer " + token } });

    // window.open(response.data.url, "_blank");
    dispatch({ type: "payment_campaign_link", payload: response.data.url });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaign",
        error,
      },
    });
  }
}
async function deleteCampaign(dispatch, campaign) {
  dispatch({ type: "loading", payload: true });
  const token = localStorage.getItem("token");
  try {
    await axios.delete(mapperRoute(`${api}/campaigns/${campaign.uuid}`), {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({ type: "redirect_to", payload: "/" });
    dispatch({ type: "campaign", payload: undefined });
    dispatch({
      type: "notify",
      payload: {
        message: "Campagne supprimée avec succès",
        type: "success",
      },
    });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when deleting campaign",
        error,
      },
    });
  }
}

async function joinCampaign(dispatch, campaignUuid) {
  dispatch({ type: "loading", payload: true });
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(mapperRoute(`${api}/campaigns/${campaignUuid}/join`), {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: "campaign",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaign",
        error,
      },
    });
  }
}

async function getCampaigns(dispatch) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    const response = await axios.get(mapperRoute(`${api}/campaigns`), { headers: { Authorization: "Bearer " + token } });
    const campaigns = response.data;
    dispatch({ type: "campaigns", payload: campaigns });
  } catch (error) {
    console.log("cant get campaigns, ", error);
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaigns",
        error,
      },
    });
  }
}

function commentReducer(state, action) {
  switch (action.type) {
    default: {
      state.error = undefined;
      state.loading = false;
      state[action.type] = action.payload;
      return { ...state };
    }
  }
}

function CampaignProvider({ children }) {
  const [state, dispatch] = React.useReducer(commentReducer, {
    loading: undefined,
    error: undefined,
    campaign: undefined,
    campaigns: undefined,
    redirect_to: undefined,
  });
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useCampaignState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCampaignState must be used within a CampaignProvider");
  }
  return context;
}

function useCampaignDispatch() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCampaignDispatch must be used within a CampaignProvider");
  }
  return context;
}

export { CampaignProvider, useCampaignState, useCampaignDispatch, getCampaign, getCampaigns, joinCampaign, createCampaign, updateCampaign, deleteCampaign, activateCampaign };

import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Center, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useGlobalState } from "../../store/GlobalStore";

function MediaUploader({ defaultValue, onChange, isEditable = true }) {
  const { color_theme } = useGlobalState();
  const [media, setMedia] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        Object.assign(file, {
          original_url: URL.createObjectURL(file),
          buffer: binaryStr,
        });
        setMedia(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [],
      "video/*": [],
    },
    onDrop,
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (media) {
      onChange(media);
    }
  }, [media]);
  return (
    <>
      {isEditable && (
        <Center cursor={"pointer"} bgColor={"#F8F8F8"} border={"2px dashed #CCC"} p={4} mt="5" mb="2" borderRadius={"10px"} {...getRootProps()}>
          <input {...getInputProps()} />
          <Box>
            <Text fontWeight={500}>
              <DownloadIcon /> {isDragActive ? "Déposez le fichier ici" : "Sélectionnez un fichier (image ou video) "}
            </Text>
          </Box>
        </Center>
      )}
      <Box bgColor={color_theme === "light" ? "#F8F8F8" : "#333"} boxShadow={"base"} borderRadius={"10px"} overflow="hidden" height="200px" cursor={"pointer"}>
        {defaultValue && (
          <Box width="100%" height="200px" bgColor={"#EEE"} overflow="hidden">
            <Center px={10} textAlign="center" width="100%" height="100%">
              {(defaultValue.mime_type || defaultValue.type).includes("video") ? <video src={defaultValue.original_url} controls style={{ height: "100%" }} /> : <Image objectFit={"contain"} src={defaultValue.original_url} alt="" w="100%" h="100%" objectPosition={"center"} />}
            </Center>
          </Box>
        )}
        {!defaultValue && (
          <Center px={10} textAlign="center" width="100%" height="100%">
            {media ? (
              <Box height="200px" bgColor={"#EEE"} overflow="hidden">
                {(media.mime_type || media.type).includes("video") ? <video src={media.original_url} controls style={{ height: "100%" }} /> : <Image objectFit={"contain"} src={media.original_url} alt="" w="100%" h="100%" objectPosition={"center"} />}
              </Box>
            ) : (
              <>Aucun média sélectionné</>
            )}
          </Center>
        )}
      </Box>
    </>
  );
}

export default MediaUploader;

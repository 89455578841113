import { Box, Button, Center, Container, FormControl, Image, Input, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import PhoneInput from "../components/global/PhoneInput";
import { loginUser, useGlobalDispatch, useGlobalState } from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";
import Footer from "../partials/Footer";

function Login() {
  const { notify, color_theme, loading, error, user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [fields, setFields] = useState({
    tel: "",
    password: "",
  });
  const toast = useToast();
  const changeField = (value, field) => {
    setFields({ ...fields, [field]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const login = () => {
    if (fields.tel !== "" && fields.password !== "") {
      loginUser(dispatch, fields);
    } else {
      toast({
        title: "Error",
        description: "Veuillez remplir tous les champs",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      toast({
        title: "Error",
        description: error?.error?.response?.data?.message || error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (notify) {
      toast({
        title: notify.title || "Succès",
        description: notify.message,
        status: notify.status || "success",
        duration: 9000,
        isClosable: true,
      });
      dispatch({ type: "notify", payload: undefined });
    }
  }, [notify?.message, notify]);

  if (user && !error) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Box w="100%" py={24} pb={18}>
        <Center>
          <Image src={color_theme === "light" ? "/logo.png" : "/logo_white.png"} height={"3rem"} alt="Logo de Com'" />
        </Center>
        <Box textAlign={"center"} mt={8} px={5}>
          <span>
            Touchez plus de personnes grâce à des centaines de partages sur
            <br />
            <strong>Whatsapp, Dikalo, Twitter, Facebook</strong>.
          </span>
        </Box>
      </Box>
      <Box textAlign={"center"} px={5}>
        <Text fontSize="4xl" fontWeight={"800"} my={10}>
          Connexion
        </Text>
        <form onSubmit={onSubmit}>
          <FormControl my="4">
            <PhoneInput placeholder="Votre numéro de téléphone" value={fields.tel} onChange={(v) => changeField(v, "tel")} />
          </FormControl>
          <FormControl my="4">
            <Input type="password" px={6} py={7} placeholder="Votre mot de passe" onChange={(e) => changeField(e.target.value, "password")} />
          </FormControl>
          <Button bgColor={primaryColor} type={"submit"} fontWeight={500} width="100%" color="white" py={7} isLoading={loading}>
            Se connecter
          </Button>
        </form>
      </Box>
      <Box my={8} mt={4} px={5} textAlign={"right"} fontSize={20}>
        <Text as="small">
          Pas encore de compte ?{" "}
          <Link to="/register">
            <Text as="span" color={primaryColor}>
              Inscrivez-vous
            </Text>
          </Link>
          .
        </Text>
      </Box>
      <Box>
        <Footer/>
      </Box>
    </Container>
  );
}

export default Login;

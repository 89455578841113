import { Alert, AlertDescription, AlertIcon, AlertTitle, Badge, Button, Container, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router";
import ActivateButton from "../components/campaigns/ActivateButton";
import ErrorBox from "../components/global/ErrorBox";
import Layout from "../components/global/Layout";
import Title from "../components/global/Title";
import Breadcrumb from "../partials/Breadcrumb";
import FormCampaign from "../partials/FormCampaign";
import Statistics from "../partials/Statistics";
import { activateCampaign, deleteCampaign, getCampaign, updateCampaign, useCampaignDispatch, useCampaignState } from "../store/CampaignStore";
import { useGlobalState } from "../store/GlobalStore";
import { MIN_PPC, MIN_PPP } from "../utils/constants";
import { isAGoodUrl } from "../utils/helpers";
import LoadingPage from "./LoadingPage";

function Campaign() {
  const { campaignUuid } = useParams();
  const { loading, error, campaign } = useCampaignState();
  const { user } = useGlobalState();
  const dispatch = useCampaignDispatch();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      name: "Accueil",
      path: "/",
    },
    {
      name: "Campagnes",
      path: "/",
    },
  ]);
  const [stats, setStats] = useState([]);
  const dateFormat = "DD-MM-YYYY à HH:mm";
  const toast = useToast();

  useEffect(() => {
    if (!campaign || campaignUuid !== campaign.uuid) {
      getCampaign(dispatch, campaignUuid);
    }
  }, [campaign, campaignUuid]);

  useEffect(() => {
    if (campaign) {
      setBreadcrumbItems([
        {
          name: "Accueil",
          path: "/",
        },
        {
          name: "Campagnes",
          path: "/",
        },
        {
          name: campaign.name,
          path: "/campaigns/" + campaign.uuid,
        },
      ]);
      setStats([
        {
          label: "Clics",
          value: campaign.stats.clics,
        },
        {
          label: "FCFA dépensé",
          value: campaign.stats.amount,
        },
        {
          label: "Participants",
          value: campaign.stats.total_participants,
        },
      ]);
    }
  }, [campaign, campaignUuid]);

  const submitForm = (fields) => {
    if (fields.name !== "" && fields.link !== "" && isAGoodUrl(fields.link) && fields.ppc >= MIN_PPC) {
      console.log(fields);
      updateCampaign(dispatch, campaign, fields);
    } else {
      toast({
        title: "Erreur - Mise à jour d'une campagne",
        description: "Veuillez bien remplir tous les champs et respecter les prix minimums.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    console.log(fields);
  };
  const onDeleteCampaign = () => {
    deleteCampaign(dispatch, campaign);
  };

  const onPayToActivateCampaign = (fields) => {
    activateCampaign(dispatch, campaign, fields);
  };

  const onActivateCampaign = () => {
    updateCampaign(dispatch, campaign, {
      active: true,
    });
  };

  const onDeactivateCampaign = () => {
    updateCampaign(dispatch, campaign, {
      active: false,
    });
  };

  const onCancelActivation = () => {
    // dispatch({ type: "loading", payload: false });
    // dispatch({ type: "error", payload: undefined });
    if (loading) window.location.reload();
  };
  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      {loading && <LoadingPage />}
      {error && <ErrorBox error={error} />}
      {campaign && (
        <>
          <Container>
            <Text fontSize={"3xl"} fontWeight={700}>
              {campaign.name} {campaign.is_active === 1 && <Badge colorScheme="green">Active</Badge>}
              {campaign.is_active === 0 && <Badge colorScheme="red">Inactive</Badge>}
            </Text>

            <Text as="small">
              Créée le <Moment format={dateFormat}>{campaign.created_at}</Moment>
            </Text>
            <br />
          </Container>
          <Container mt="4">
            {!campaign.is_active && (
              <Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" minHeight="200px">
                <AlertIcon my={4} boxSize="40px" mr={0} />

                <AlertTitle my={2}>Félicitation pour votre nouvelle campagne 🎉</AlertTitle>
                {campaign.ppc * 100 > user.balance ? (
                  <AlertDescription>
                    <strong>Important</strong> : Il vous faut à présent l'activer. Pour le faire, vous devrez effectuer un paiement équivalent à <strong>100 fois votre prix par clic, soit {campaign.ppc * 100} XAF</strong>. 
                    Ce montant est une réserve de paiement.
                    <br />
                    <ActivateButton campaign={campaign} user={user} onSubmit={onPayToActivateCampaign} onCancel={onCancelActivation} />
                  </AlertDescription>
                ) : (
                  <AlertDescription>
                    <Button my={4} colorScheme="green" onClick={onActivateCampaign}>
                      Activer ma campagne
                    </Button>
                  </AlertDescription>
                )}
              </Alert>
            )}
          </Container>

          <Statistics stats={stats} />
          <Container>
            <Title>Informations</Title>

            <FormCampaign campaign={campaign} onSubmit={submitForm} deactivateCampaign={onDeactivateCampaign} deleteCampaign={onDeleteCampaign} loading={loading} initValues={campaign} submitText="Mettre à jour" />
          </Container>
        </>
      )}
    </Layout>
  );
}

export default Campaign;

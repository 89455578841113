import axios from "axios";
import React from "react";
import { api } from "../configs/api";
import { mapperRoute, mapperUserFields } from "../utils/helpers";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

async function registerUser(dispatch, { tel, password, name }) {
  dispatch({ type: "loading", payload: true });
  try {
    const response = await axios.post(mapperRoute(`${api}/register`), { phone_number: tel, password, name, role: "company" });
    const login_success = response.data;
    dispatch({ type: "login_success", payload: login_success });
    dispatch({
      type: "notify",
      payload: {
        title: "Bienvenue 🎉.",
        message: "Créez vos campagnes et touchez plus de monde.",
      },
    });
  } catch (error) {
    dispatch({
      type: "error",
      payload: { message: "An error occur when login", error },
    });
  }
}
async function loginUser(dispatch, { tel, password }) {
  dispatch({ type: "loading", payload: true });
  try {
    // const response = await axios.post(mapperRoute(`${api}/login`), { tel, password });
    const response = await axios.post(mapperRoute(`${api}/login`), { phone_number: tel, password });
    dispatch({ type: "login_success", payload: response.data });
    dispatch({
      type: "notify",
      payload: {
        message: "Bon retour " + (response?.data?.user?.first_name || response?.data?.user?.name) + " 🎉.",
      },
    });
  } catch (error) {
    dispatch({
      type: "error",
      payload: { message: "An error occur when login", error },
    });
  }
}

async function getUser(dispatch) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    if (token) {
      const response = await axios.get(mapperRoute(`${api}/user`), {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      dispatch({ type: "user", payload: response.data });
    } else {
      throw new Error("Token not found");
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    dispatch({
      type: "error",
      payload: { message: "An error occur when getting the user", error },
    });
  }
}

async function updateUserInfos(dispatch, fields) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    const response = await axios.put(mapperRoute(`${api}/user`), mapperUserFields(fields), { headers: { Authorization: "Bearer " + token } });
    dispatch({ type: "update_user_success", payload: response.data });
    console.log("notify");
    dispatch({
      type: "notify",
      payload: {
        message: "Informations de l'utilisateur mises à jour avec succès.",
      },
    });
  } catch (error) {
    console.log("updateError", error.error);
    dispatch({
      type: "error",
      payload: { message: "An error occur when updating user infos. Error : " + (error?.message || ""), error },
    });
  }
}

async function getAllTransactions(dispatch) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    // const response = await axios.post(mapperRoute(`${api}/login`), { tel, password });
    const response = await axios.get(mapperRoute(`${api}/transactions`), { headers: { Authorization: "Bearer " + token } });
    dispatch({ type: "transactions", payload: response.data });
  } catch (error) {
    dispatch({
      type: "error",
      payload: { message: "An error occur when getting the transactions of the user", error },
    });
  }
}

function commentReducer(state, action) {
  switch (action.type) {
    case "login_success": {
      const token = action.payload.access_token;
      localStorage.setItem("token", token);
      return { ...state, user: action.payload.user, token: token, loading: false, error: undefined };
    }
    case "update_user_success": {
      return { ...state, user: action.payload, loading: false, error: undefined };
    }
    case "error": {
      return { ...state, error: action.payload, loading: false };
    }
    default: {
      state.loading = false;
      state.error = undefined;
      state[action.type] = action.payload;
      return { ...state };
    }
  }
}

function GlobalProvider({ children }) {
  const [state, dispatch] = React.useReducer(commentReducer, {
    loading: undefined,
    token: undefined,
    user: undefined,
    notify: undefined,
    color_theme: localStorage.getItem("chakra-ui-color-mode") || "light",
  });
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useGlobalState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
}

function useGlobalDispatch() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalDispatch must be used within a GlobalProvider");
  }
  return context;
}

export { GlobalProvider, useGlobalState, useGlobalDispatch, loginUser, registerUser, getAllTransactions, getUser, updateUserInfos };
